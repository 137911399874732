<template>
    <PageWrapper>
        <SwitchAccountGrid
            label="Select Account"
            @select="$emit('select', $event)"
            @error="$log('Failed to select an account...', $event)"
        />
    </PageWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount } from 'vue';
    import profile from '@/api/v2/profile';

    // <!-- COMPOSABLES -->
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';
    import { useNARAFeature } from '@/utils/features';

    // <!-- COMPONENTS -->
    import PageWrapper from '@/components/PageWrapper.vue';

    // <!-- FIXTURES -->
    import SwitchAccountGrid from '@/features/switch-account/components/SwitchAccountGrid.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectAccount',
        components: {
            PageWrapper,
            SwitchAccountGrid,
        },
        emits: ['select', 'error'],
        setup(props, context) {
            // ==== COMPOSABLES ====
            const store = useStore();
            const router = useRouter();
            const { isNARADisabled } = useNARAFeature();
            const {
                fetchCurrentUser,
                isCurrentSubscriptionMissing,
                isCurrentSubscriptionExpired,
                isSuperUser,
            } = useExpiredSubscriptionGate();

            // Redirect back to the login page, if no user.
            const redirectToLogin = async () => {
                console.log(`No user is logged in.`);
                await store.dispatch('logout');
                await router.push('/login');
            };

            // Handle initial lifecycle.
            onBeforeMount(async () => {
                // Update the current user.
                await fetchCurrentUser();

                // Save the default organization.
                if (
                    isNARADisabled.value &&
                    !isSuperUser.value &&
                    isCurrentSubscriptionMissing.value
                ) {
                    // Redirect to the login page.
                    return await redirectToLogin();
                }

                // Save the default organization.
                if (
                    !isSuperUser.value &&
                    isNARADisabled.value &&
                    isCurrentSubscriptionExpired.value
                ) {
                    // Redirect to the expired subscription page.
                    return await router.push('/expired-subscription');
                }
            });

            // Expose properties.
            return {
                isSuperUser,
            };
        },
    });
</script>
